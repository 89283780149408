"use client";
import CustomCarousel from "@/components/CustomCarousel/CustomCarousel";
import "./successStories.scss";
import StoryCard from "./StoryCard/StoryCard";
import Link from "next/link";
import { useIntl } from "react-intl";

export default function SuccessStories({ itemsPerPage, successStories }) {
  const intl = useIntl();

  return (
    <div className="successStoriesWrapper">
      <p className="successStoriesHeading">
        {intl.formatMessage({ id: "success" })}{" "}
        <span className="highlighted">
          {intl.formatMessage({ id: "stories" })}
        </span>
      </p>
      {successStories?.data?.length > 0 && (
        <CustomCarousel itemsPerPage={itemsPerPage}>
          {successStories?.data?.map((story, i) => {
            return <StoryCard key={i} story={story} />;
          })}
        </CustomCarousel>
      )}
      <Link href={"/success-stories"} className="viewMoreBtn">
        {intl.formatMessage({ id: "viewAllArticles" })}
      </Link>
    </div>
  );
}
