"use client";
import "./careScreenBanner.scss";
import Image from "next/image";
import { countryData } from "@/utils/data";
import Marquee from "react-fast-marquee";
import { useState, useRef, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useIntl } from "react-intl";
import {
  searchTopDoctors,
  searchTopHospitals,
  searchTreatments,
} from "@/slice/searchSlice";
import { debounce } from "@/utils/debounce";
import Link from "next/link";
import { routeName } from "@/utils/commonfuntions";

export default function CareScreenBanner() {
  const [modal, setModal] = useState(false);
  const dispatch = useDispatch();

  const doctors = useSelector((state) => state.search.doctors);
  const hospitals = useSelector((state) => state.search.hospitals);
  const treatments = useSelector((state) => state.search.treatments);

  // Fix the debounced search implementation
  const handleSearch = useRef(
    debounce((query) => {
      if (query) {
        dispatch(searchTopDoctors(query));
        dispatch(searchTopHospitals(query));
        dispatch(searchTreatments(query));
      }
    }, 500),
  ).current;

  const handleInputChange = (event) => {
    const query = event.target.value;
    handleSearch(query);
  };

  const intl = useIntl();

  const modalRef = useRef(null);

  const handleClickOutside = (event) => {
    if (modalRef.current && !modalRef.current.contains(event.target)) {
      setModal(false);
    }
  };

  useEffect(() => {
    if (modal) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [modal]);
  return (
    <>
      {modal && (
        <div
          className="fixed inset-0 bg-black/30 z-[1100]"
          aria-hidden="true"
        />
      )}
      <div className="careBannerWrapper relative">
        <div className="careBannerInfoContainer">
          <div className="careBannerInfo">
            <p className="heading1">{intl.formatMessage({ id: "heading1" })}</p>
            <p className="heading2">{intl.formatMessage({ id: "heading2" })}</p>
            <p className="heading3">
              <h4>{intl.formatMessage({ id: "heading3" })}</h4>

              <span className="heading4">
                <h1>{intl.formatMessage({ id: "heading4" })}</h1>
              </span>
            </p>
          </div>
          <div className="careBannerSearchWrapper relative w-full max-w-2xl mx-auto">
            <p className="heading5 mb-4">
              {intl.formatMessage({ id: "heading5" })}
            </p>
            <div className="searchContainer relative w-full">
              <div className="searchInputContainer relative w-full">
                <input
                  onClick={() => setModal(true)}
                  onChange={handleInputChange}
                  className="searchInput w-full px-4 py-3 rounded-lg border border-gray-200 focus:outline-none focus:border-blue-500 transition-colors"
                  type="text"
                  name="searchCare"
                  placeholder={intl.formatMessage({
                    id: "searchPlaceholder",
                  })}
                />
                {modal && (
                  <div
                    ref={modalRef}
                    className="fixed left-1/2 -translate-x-1/2 w-[90vw] md:w-full max-w-2xl mt-2 bg-white rounded-lg shadow-xl border border-gray-100 max-h-[70vh] overflow-y-auto z-[1200] top-[120px] md:absolute md:top-full md:left-0 md:translate-x-0"
                    style={{ minWidth: "320px" }}
                  >
                    <div className="divide-y divide-gray-100 w-full">
                      {!doctors.data?.length &&
                      !treatments.data?.length &&
                      !hospitals.data?.length ? (
                            <div className="p-8 text-center">
                              <p className="text-gray-500">No results found</p>
                            </div>
                          ) : (
                            <>
                              {/* Doctors Section */}
                              {doctors.status === "succeeded" &&
                            doctors.data?.length > 0 && (
                                <div className="p-4">
                                  <div className="flex items-center gap-3 mb-3">
                                    <div className="flex-shrink-0 mt-[15px]">
                                      <svg
                                        className="w-5 h-5 text-blue-600"
                                        fill="none"
                                        stroke="currentColor"
                                        viewBox="0 0 24 24"
                                      >
                                        <path
                                          strokeLinecap="round"
                                          strokeLinejoin="round"
                                          strokeWidth="2"
                                          d="M16 7a4 4 0 11-8 0 4 4 0 018 0zM12 14a7 7 0 00-7 7h14a7 7 0 00-7-7z"
                                        />
                                      </svg>
                                    </div>
                                    <h3 className="text-lg font-semibold text-gray-900 leading-none">
                                    Doctors
                                    </h3>
                                  </div>

                                  <div className="space-y-2">
                                    {doctors.status === "loading" ? (
                                      <div className="flex items-center justify-center py-4">
                                        <div className="w-6 h-6 border-2 border-blue-600 border-t-transparent rounded-full animate-spin"></div>
                                        <p className="ml-2 text-sm text-gray-600">
                                          {intl.formatMessage({
                                            id: "modalLoading",
                                          })}
                                        </p>
                                      </div>
                                    ) : doctors.status === "succeeded" &&
                                    doctors?.data?.length > 0 ? (
                                          doctors.data.map((doctor) => (
                                            <Link
                                              key={doctor?.name}
                                              href={`/doctors/${routeName(
                                                doctor?.slug,
                                              )}`}
                                              className="block p-2 hover:bg-blue-50 rounded-md transition-colors duration-150"
                                            >
                                              <div className="flex flex-col">
                                                <span className="text-gray-900 font-medium">
                                                  {doctor?.name}
                                                </span>
                                                {doctor?.specialization && (
                                                  <span className="text-sm text-gray-500">
                                                    {doctor?.specialization}
                                                  </span>
                                                )}
                                              </div>
                                            </Link>
                                          ))
                                        ) : (
                                          <p className="text-center text-sm text-gray-500 py-2">
                                      No doctors found
                                          </p>
                                        )}
                                  </div>
                                </div>
                              )}

                              {/* Treatments Section */}
                              {treatments.status === "succeeded" &&
                            treatments.data?.length > 0 && (
                                <div className="p-4">
                                  <div className="flex items-center gap-3 mb-3">
                                    <div className="flex-shrink-0 mt-[15px]">
                                      <svg
                                        className="w-5 h-5 text-green-600"
                                        fill="none"
                                        stroke="currentColor"
                                        viewBox="0 0 24 24"
                                      >
                                        <path
                                          strokeLinecap="round"
                                          strokeLinejoin="round"
                                          strokeWidth="2"
                                          d="M19.428 15.428a2 2 0 00-1.022-.547l-2.387-.477a6 6 0 00-3.86.517l-.318.158a6 6 0 01-3.86.517L6.05 15.21a2 2 0 00-1.806.547M8 4h8l-1 1v5.172a2 2 0 00.586 1.414l5 5c1.26 1.26.367 3.414-1.415 3.414H4.828c-1.782 0-2.674-2.154-1.414-3.414l5-5A2 2 0 009 10.172V5L8 4z"
                                        />
                                      </svg>
                                    </div>
                                    <h3 className="text-lg font-semibold text-gray-900 leading-none">
                                    Treatments
                                    </h3>
                                  </div>

                                  <div className="space-y-2">
                                    {treatments.status === "loading" ? (
                                      <div className="flex items-center justify-center py-4">
                                        <div className="w-6 h-6 border-2 border-green-600 border-t-transparent rounded-full animate-spin"></div>
                                        <p className="ml-2 text-sm text-gray-600">
                                          {intl.formatMessage({
                                            id: "modalLoading",
                                          })}
                                        </p>
                                      </div>
                                    ) : (
                                      treatments.data.map((treatment) => (
                                        <Link
                                          key={treatment?.name}
                                          href={`/specialities/${routeName(
                                            treatment?.slug,
                                          )}`}
                                          className="block p-2 hover:bg-green-50 rounded-md transition-colors duration-150"
                                        >
                                          <div className="flex flex-col">
                                            <span className="text-gray-900 font-medium">
                                              {treatment?.name}
                                            </span>
                                            {treatment?.description && (
                                              <span className="text-sm text-gray-500 truncate">
                                                {treatment?.description}
                                              </span>
                                            )}
                                          </div>
                                        </Link>
                                      ))
                                    )}
                                  </div>
                                </div>
                              )}

                              {/* Hospitals Section */}
                              {hospitals.status === "succeeded" &&
                            hospitals.data?.length > 0 && (
                                <div className="p-4">
                                  <div className="flex items-center gap-3 mb-4">
                                    <div className="flex-shrink-0 mt-[15px]">
                                      <svg
                                        className="w-5 h-5 text-purple-600"
                                        fill="none"
                                        stroke="currentColor"
                                        viewBox="0 0 24 24"
                                      >
                                        <path
                                          strokeLinecap="round"
                                          strokeLinejoin="round"
                                          strokeWidth="2"
                                          d="M19 21V5a2 2 0 00-2-2H7a2 2 0 00-2 2v16m14 0h2m-2 0h-5m-9 0H3m2 0h5M9 7h1m-1 4h1m4-4h1m-1 4h1m-5 10v-5a1 1 0 011-1h2a1 1 0 011 1v5m-4 0h4"
                                        />
                                      </svg>
                                    </div>
                                    <h3 className="text-lg font-semibold text-gray-900 leading-none">
                                    Hospitals
                                    </h3>
                                  </div>

                                  <div className="space-y-2">
                                    {hospitals.data.map((hospital) => (
                                      <Link
                                        key={hospital?.name}
                                        href={`/hospitals/${routeName(
                                          hospital?.slug,
                                        )}`}
                                        className="block p-3 hover:bg-purple-50 rounded-md transition-colors duration-150"
                                      >
                                        <div className="flex flex-col">
                                          <span className="text-gray-900 font-medium">
                                            {hospital?.name}
                                          </span>
                                          {hospital?.location && (
                                            <span className="text-sm text-gray-500">
                                              {hospital?.location}
                                            </span>
                                          )}
                                        </div>
                                      </Link>
                                    ))}
                                  </div>
                                </div>
                              )}
                            </>
                          )}
                    </div>
                  </div>
                )}
              </div>
            </div>
            <p className="heading6 mt-4">
              {intl.formatMessage({ id: "heading6" })}
            </p>
          </div>

          <div>
            <Image
              loading="lazy"
              src="/images/deviderLine.svg"
              alt="devide line icon"
              width={0}
              height={0}
              style={{
                width: "100%",
                height: "auto",
                margin: "20px 0px",
              }}
            />
          </div>

          <Marquee className="countriesContainer" speed={20}>
            {countryData.map((country, index) => (
              <div
                key={index}
                className="flex items-center gap-4 text-xs font-normal text-[#454f66] tracking-[.48px] border border-dashed border-[#e2e5ed] rounded-[19px] p-[6px] min-w-[115px] mx-[3px]"
              >
                <Image
                  loading="lazy"
                  src={`/images/${country.name.toLowerCase()}.svg`}
                  alt="icon of country"
                  width={25}
                  height={25}
                />
                <p className="my-auto">{country.name}</p>
              </div>
            ))}
          </Marquee>
        </div>
        <div className="careBannerBg">
          <Image
            src="/images/careScreenBg.png"
            alt="care screen banner background"
            // fill
            height={484}
            width={474}
            priority
            // loading="lazy"
            // sizes="auto"
            layout="responsive"
            className="w-[90%] h-full object-cover"
          />
        </div>
      </div>
    </>
  );
}
