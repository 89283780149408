import { routeName } from "@/utils/commonfuntions";
import "./treatmentPackageCard.scss";
import Image from "next/image";
import Link from "next/link";
import { useIntl } from "react-intl";
import { useState } from "react";

export default function TreatmentPackageCard({ packageDetails }) {
  const [isExpanded, setIsExpanded] = useState(false);
  const intl = useIntl();

  return (
    <Link
      href={{
        pathname: `/packages/${routeName(packageDetails.slug)}`,
      }}
    >
      <div className="packageContainer hover:shadow-lg transition-all duration-300 w-[355px] h-[370px]">
        <div className="packageImgContainer">
          <Image
            loading="lazy"
            src={packageDetails?.img?.formats?.small?.url || packageDetails?.img?.url || '/placeholder.jpg'}
            alt={packageDetails?.packageName || 'Treatment Package'}
            fill
            style={{ objectFit: "cover" }}
          />
        </div>
        <div className="packageInfoContainer min-h-[175px]">
          <p className="packageName">{packageDetails?.packageName}</p>
          <div className="relative">
            <p className={`text-gray-600 mt-2 text-sm ${!isExpanded ? 'line-clamp-2' : ''}`}>
              {packageDetails?.description}
            </p>
            {packageDetails?.description?.length > 100 && (
              <button
                onClick={(e) => {
                  e.preventDefault();
                  setIsExpanded(!isExpanded);
                }}
                className="text-blue-600 text-sm hover:text-blue-700 mt-1"
              >
                {isExpanded ? 'Read Less' : 'Read More'}
              </button>
            )}
          </div>
          <div className="devider mt-auto"></div>
          <p className="packageCost text-center">
            <span className="highlight">Contact Us</span>
          </p>
        </div>
      </div>
    </Link>
  );
}
