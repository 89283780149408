"use client";
import CustomCarousel from "@/components/CustomCarousel/CustomCarousel";
import "./blogsCarousel.scss";
import Link from "next/link";
import BlogCard from "./BlogCard/BlogCard";
import { useDispatch, useSelector } from "react-redux";
import { fetchBlogs, selectAllBlogs } from "@/slice/blogsSlice";
import { useEffect } from "react";
import { useIntl } from "react-intl";

export default function BlogsCarousel({ itemsPerPage, blogsData }) {
  const dispatch = useDispatch();
  const Data = useSelector(selectAllBlogs);
  const blog = blogsData || Data;
  const intl = useIntl();

  const lang = useSelector((state) => state.locale.locale);
  useEffect(() => {
    !blogsData && dispatch(fetchBlogs(lang));
  }, [lang]);

  return (
    <div className="blogsWrapper">
      <div className="blogsContainer">
        <p className="blogsHeading">
          <span className="highlight">
            {intl.formatMessage({ id: "blogs" })}
          </span>
        </p>
        <CustomCarousel itemsPerPage={itemsPerPage}>
          {blog?.data?.length > 0 &&
            [...blog.data]
              .sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt))
              .map((blog, i) => <BlogCard key={i} blog={blog} />)}
        </CustomCarousel>
        <Link href="/blogs" className="viewMoreBtn">
          {intl.formatMessage({ id: "viewAllBlogs" })}
        </Link>
      </div>
    </div>
  );
}
