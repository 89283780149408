export const VideoEmbed = ({ video, className }) => {
  const videoData = JSON.parse(video);
  return (
    <div>
      <h2 className="text-2xl text-justify">{videoData?.title}</h2>
      <div
        className={className && className}
        dangerouslySetInnerHTML={{ __html: videoData?.rawData?.html }}
      />
    </div>
  );
};
