"use client";
import { memo } from "react";
import "./careScreenStatistics.scss";
import Image from "next/image";
import CountUp from "react-countup";
import { useIntl } from "react-intl";

const StatItem = ({ count, headingId }) => {
  const intl = useIntl();

  return (
    <div className="statsInfo">
      <p className="statsCount">
        <CountUp end={count} duration={3} />
      </p>
      <p className="statsHeading">{intl.formatMessage({ id: headingId })}</p>
    </div>
  );
};

const CareSection = ({ statistics }) => {
  const intl = useIntl();

  const stats = statistics?.data;

  if (!stats) return null;

  return (
    <div className="statsWrapper">
      <div className="statsContainer">
        <div className="statsNumbers">
          <StatItem
            count={stats?.Happy_Patients}
            headingId="patientServedHeading"
          />
          <Image
            loading="lazy"
            src="/images/verticalLine.svg"
            alt="vertical line icon"
            width={0}
            height={0}
            style={{ width: "auto", height: "auto" }}
          />
          <StatItem
            count={stats?.Hospital_Partners}
            headingId="hospitalPartnersHeading"
          />

          <Image
            loading="lazy"
            src="/images/verticalLine.svg"
            alt="vertical line icon"
            width={0}
            height={0}
            style={{ width: "auto", height: "auto" }}
          />

          <StatItem
            count={stats?.Countries_Reached}
            headingId="countriesReachedHeading"
          />
        </div>
        <div className="verticalDevider"></div>
        <p className="statsTagline">
          {intl.formatMessage({ id: "statsTagline" })}
          <br />
          <span className="price">
            {intl.formatMessage({ id: "superiorCare" })}
          </span>
        </p>
      </div>
    </div>
  );
};

export default memo(CareSection);
