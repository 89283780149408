"use client";
import StepCard from "./StepCard/StepCard";
import "./TreatmentSteps.scss";
import { useIntl } from "react-intl";
import { steps } from "@/utils/data";

export default function TreatmentSteps() {
  const intl = useIntl();

  return (
    <div className="treatmentStepsWrapper">
      <p className="treatmentStepsTagline">
        {intl.formatMessage({ id: "treatmentStepsTagline" })}
        <br />
        <span className="boldText">
          {intl.formatMessage({ id: "boldText" })}
        </span>
      </p>
      <div className="stepsContainer">
        {steps.map((step, i) => {
          return <StepCard key={step.id} step={step} />;
        })}
      </div>
      <div className="noChargesContainer">
        <p>*We do not collect any charges</p>
      </div>
    </div>
  );
}
