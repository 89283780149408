'use client';
import { useState } from 'react';

export default function VideoCard({ video }) {
  const [showVideo, setShowVideo] = useState(false);

  const getVideoId = (url) => {
    if (!url) return null;
    const match = url.match(
      /(?:youtu\.be\/|youtube\.com(?:\/embed\/|\/v\/|\/watch\?v=|\/watch\?.+&v=))([\w-]{11})/
    );
    return match ? match[1] : null;
  };

  const videoId = getVideoId(video?.url);
  const thumbnailUrl = videoId
    ? `https://img.youtube.com/vi/${videoId}/maxresdefault.jpg`
    : '/default-thumbnail.jpg';
  const embedUrl = videoId
    ? `https://www.youtube.com/embed/${videoId}?autoplay=1`
    : '';

  if (!videoId) return null;

  return (
    <div className="w-full max-w-[400px] bg-white rounded-[20px] shadow-md overflow-hidden border border-gray-100 p-5">
      <div className="relative pt-[56.25%] bg-gray-50">
        {!showVideo ? (
          <>
            <img
              src={thumbnailUrl}
              alt={video.title}
              className="absolute inset-0 w-full h-full object-cover rounded-[20px]"
            />
            <div className="absolute inset-0 bg-black/10  rounded-[20px]" />
            <button
              onClick={() => setShowVideo(true)}
              className="absolute inset-0 w-full h-full flex items-center justify-center"
              aria-label="Play video"
            >
              <svg
                width="54"
                height="57"
                viewBox="0 0 54 57"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M48.767 21.108C54.6906 24.3293 54.6906 32.668 48.767 35.8894L13.0002 55.3392C7.24303 58.47 0.167969 54.395 0.167969 47.9485V9.04888C0.167969 2.6023 7.24303 -1.47258 13.0002 1.65816L48.767 21.108Z"
                  fill="white"
                />
              </svg>
            </button>
          </>
        ) : (
          <iframe
            className="absolute inset-0 w-full h-full"
            src={embedUrl}
            title={video.title}
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
          />
        )}
      </div>
      <div >
        <p className="text-[15px] text-gray-800 font-medium line-clamp-1 mt-2.5">
          {video.title}
        </p>
      </div>
    </div>
  );
}
