import { Button } from "@/components/ui/button";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "@/components/ui/dialog";
import { Input } from "@/components/ui/input";
import { Label } from "@/components/ui/label";
import { VideoEmbed } from "@/components/video";

export function DialogDemo({ showDialog, closeDialog, videoUrl }) {
  return (
    <Dialog open={showDialog} onOpenChange={closeDialog}>
      <DialogTrigger asChild></DialogTrigger>
      <DialogContent className="sm:max-w-[825px] w-full">
        <div className="grid gap-4 py-4 p-10">
          <VideoEmbed video={videoUrl} dev />
        </div>
        <DialogFooter>
          <Button onClick={closeDialog}>Close</Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
}
