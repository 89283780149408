import "./stepCard.scss";
import { useIntl } from "react-intl";

export default function StepCard({ step }) {
  const intl = useIntl();
  return (
    <div className="stepWrapper">
      <div className="stepContainer" key={step.id}>
        <div className="stepNumContainer">
          <div className="stepNum" style={{ background: step.bgColor }}>
            {step.id}
          </div>
          <div
            className="verticalLine"
            style={{ borderColor: step.bgColor }}
          ></div>
        </div>
        <p className="stepHeading" style={{ color: step.bgColor }}>
          {intl.formatMessage({ id: step.name })}
        </p>
      </div>
      <div className="borderBottom" style={{ background: step.bgColor }}></div>
    </div>
  );
}
