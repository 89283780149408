"use client";
import ServiceCard from "./ServiceCard/ServiceCard";
import "./services.scss";

export default function Services({ treatment }) {
  return (
    <div className="servicesWrapper">
      {treatment?.data?.length > 0 &&
        treatment?.data?.map((service, i) => {
          return <ServiceCard service={service} key={i} />;
        })}
    </div>
  );
}
