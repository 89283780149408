"use client";
import "./treatmentPackagesCarousel.scss";
import TreatmentPackageCard from "./TreatmentPackageCard/TreatmentPackageCard";
import CustomCarousel from "@/components/CustomCarousel/CustomCarousel";
import Link from "next/link";
import {
  fetchTreatmentPackages,
  selectAllTreatmentPackages,
  selectAllTreatmentPackagesStatus,
} from "@/slice/treatmentPackageSlice";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { useIntl } from "react-intl";
import FullScreenLoader from "../FullScreenLoader/FullScreenLoader";

export default function TreatmentPackages({ itemsPerPage, data }) {
  const dispatch = useDispatch();
  const selectAllTreatmentPackagesData = useSelector(selectAllTreatmentPackages);
  const treatmentPackage = data || selectAllTreatmentPackagesData;
  const selectAllTreatmentPackagesStatusInfo = useSelector(
    selectAllTreatmentPackagesStatus
  );
  const intl = useIntl();
  const lang = useSelector((state) => state.locale.locale);

  useEffect(() => {
    if (!data) {
      dispatch(fetchTreatmentPackages({ locale: lang }));
    }
  }, [lang, data, dispatch]);

  const packages = treatmentPackage?.treatment_packages || treatmentPackage?.data || [];

  return (
    <>
      <div className="treatmentPackageWrapper">
        <h1 className="p-0 m-0">
          <p className="treatmentPackageHeading">
            {intl.formatMessage({ id: "sponsoredTop" })}{" "}
            <span className="bold">
              {intl.formatMessage({
                id: "treatmentPackageHeading",
              })}
            </span>
          </p>
        </h1>
        {packages.length > 0 && (
          <CustomCarousel itemsPerPage={itemsPerPage}>
            {packages.map((packageDetails, i) => (
              <TreatmentPackageCard
                key={packageDetails.id || i}
                packageDetails={{
                  ...packageDetails,
                  attributes: {
                    ...packageDetails,
                    img: packageDetails.img || null
                  }
                }}
              />
            ))}
          </CustomCarousel>
        )}
        <Link href="/packages">
          <button className="viewAllBtn">
            {intl.formatMessage({ id: "viewAllBtn" })}
          </button>
        </Link>
        <FullScreenLoader
          isLoading={selectAllTreatmentPackagesStatusInfo === "loading"}
        />
      </div>
    </>
  );
}
