import React from "react";
import Markdown from "react-markdown";
import rehypeRaw from "rehype-raw";
import rehypeSanitize from "rehype-sanitize";

export default function MarkdownComp({ content }) {
  return (
    <Markdown
      data-overlay-light="10"
      className={`max-w-[1440px] xl:px-[156px] px-[15px] sm:px-10 w-full mx-auto justify-start items-start `}
      components={{
        p: ({ node, ...props }) => {
          return <p {...props} className="mb-4  text-justify text-base" />;
        },
        h2: ({ node, ...props }) => {
          return <h2 {...props} className="my-4 text-justify text-2xl" />;
        },
        a: ({ node, ...props }) => {
          return (
            <a
              {...props}
              className="text-blue-500 underline hover:text-blue-700"
              target="_blank"
              rel="noopener noreferrer"
            />
          );
        },
        img: ({ node, ...props }) => {
          return (
            <img
              {...props}
              className="max-w-full h-auto"
              alt={props.alt || "Image"}
            />
          );
        },
        li: ({ node, ...props }) => {
          return (
            <ul className="mb-4 text-justify text-base">
              <li {...props} />
            </ul>
          );
        },
      }}
      rehypePlugins={[rehypeRaw, rehypeSanitize]}
    >
      {content}
    </Markdown>
  );
}
